import _cloneDeep from "lodash/cloneDeep";
import { call, fork, ForkEffect, put, select, takeEvery } from "redux-saga/effects";

import { getUrlPrefix } from "Helpers/utils";
import { IRegulation } from "SP/documents/regulations/regulations.types";
import { ILibraryFolder } from "SP/helpers.types";
import { RecentService } from "SP/recent/recent.service";
import { SitePagesService } from "SP/sitePages/sitePages.service";
import { IAttachedFile, IAttachedFiles, ISitePage, ISitePageVersion, LibraryName } from "SP/sitePages/sitePages.types";
import {
  addNewSitePageFailure,
  addNewSitePageSuccess,
  attachSitePageToDocumentsFailure,
  attachSitePageToDocumentsSuccess,
  checkOutSitePageFailure,
  checkOutSitePageSuccess,
  discardCheckOutSitePageFailure,
  discardCheckOutSitePageSuccess,
  getSitePageByUrlFailure,
  getSitePageByUrlSuccess,
  getSitePagesHierarchyFailure,
  getSitePagesHierarchySuccess,
  getSitePageVersionsSuccess,
  IAddNewSitePageRequestAction,
  IAttachSitePageToDocumentsRequestAction,
  ICheckOutSitePageRequestAction,
  IDiscardCheckOutSitePageRequestAction,
  IGetSitePageByFriendlyUrlRequestAction,
  IGetSitePageByUrlRequestAction,
  IGetSitePageVersionsAction,
  IRepublishSitePageRequestAction,
  ISaveAsDraftSitePageRequestAction,
  ISelectSitePageVersionAction,
  ISetSitePageVersionAction,
  ISetSitePageVersionHistoryModeAction,
  republishSitePageFailure,
  republishSitePageSuccess,
  saveAsDraftSitePageFailure,
  saveAsDraftSitePageSuccess,
  selectSitePageVersionFailure,
  selectSitePageVersionSuccess,
  setSitePageVersionFailure,
  setSitePageVersionHistoryModeFailure,
  setSitePageVersionHistoryModeSuccess,
  setSitePageVersionSuccess,
  SitePagesActionsTypes,
  updateSitePageFiles,
} from "Store/actions/site-pages.actions";
import { IRootReducerState } from "Store/reducers";
import { ISitePageVersions } from "Store/reducers/site-pages.reducer";

import { getAllDocuments, getAllTerms, getStandardsIcons } from "./helpers/utils.sagas";

const sitePagesService = new SitePagesService();
const recentService = new RecentService();

const sitePageSelector = (url) => (state: IRootReducerState) => state.sitePages.sitePages[url];
const hierarchySelector = (state: IRootReducerState) => state.sitePages.sitePagesHierarchy;
const sitePageVersionsSelector = (pageId) => (state: IRootReducerState) => state.sitePages.versions[pageId];
const pageVersionsSelector = (state: IRootReducerState) => state.sitePages.versions;

function* setAttachedFiles(attachedFiles: IAttachedFiles) {
  if (attachedFiles) {
    const allDocuments = yield call(getAllDocuments);
    const libraryFiles = (allDocuments[attachedFiles.library] as IRegulation[]) || [];

    return libraryFiles
      .filter((doc) => attachedFiles.items.includes(doc.Id))
      .sort((a, b) => attachedFiles.items.indexOf(a.Id) - attachedFiles.items.indexOf(b.Id));
  }
  return [];
}

function* handleSitePage(sitePage: ISitePage, url: string) {
  if (sitePage.pageInfo === null) {
    sitePage.pageInfo = {
      attachedFiles: { library: LibraryName.regulations, items: [] },
      showSubscribeButton: false,
      sections: [],
    };
  }
  if (!sitePage.redirectToPage) {
    yield call([recentService, "addPageToRecent"], sitePage);
  }

  yield put(getSitePageByUrlSuccess({ url, sitePage }));
  const files: IAttachedFile[] = yield setAttachedFiles(sitePage.pageInfo?.attachedFiles);
  yield put(updateSitePageFiles({ files, url }));
}

export function* getSitePageByFriendlyUrl(action: IGetSitePageByFriendlyUrlRequestAction) {
  try {
    let sitePage: ISitePage = yield select(sitePageSelector(action.friendlyUrl));

    if (!sitePage) {
      const allTerms = yield call(getAllTerms);
      sitePage = yield call([sitePagesService, "getSitePageByFriendlyUrl"], action.friendlyUrl, allTerms);
    }

    yield handleSitePage(sitePage, action.friendlyUrl);
  } catch (e) {
    yield put(getSitePageByUrlFailure(e));
    throw e;
  }
}

export function* getSitePageByUrl(action: IGetSitePageByUrlRequestAction) {
  try {
    let sitePage: ISitePage = yield select(sitePageSelector(action.url));

    if (!sitePage) {
      const UrlPrefix = getUrlPrefix();
      const url = `${UrlPrefix}${action.url}`;
      const allTerms = yield call(getAllTerms);
      sitePage = yield call([sitePagesService, "getSitePageByServerRelativeUrl"], url, allTerms);
    }

    if (sitePage.friendlyUrl) {
      yield handleSitePage(sitePage, sitePage.friendlyUrl);
    }

    yield handleSitePage(sitePage, action.url);
  } catch (e) {
    yield put(getSitePageByUrlFailure(e));
    throw e;
  }
}

export function* getSitePagesHierarchy() {
  try {
    let hierarchy: ILibraryFolder[] = yield select(hierarchySelector);

    if (!hierarchy.length) {
      hierarchy = yield call([sitePagesService, "getFolderHierarchy"]);
    }

    yield put(getSitePagesHierarchySuccess(hierarchy));
  } catch (e) {
    yield put(getSitePagesHierarchyFailure(e));
    throw e;
  }
}

export function* getSitePageVersions(action: IGetSitePageVersionsAction) {
  try {
    const sitePage: ISitePage = yield select(sitePageSelector(action.pageUrl));
    const versions = yield select(sitePageVersionsSelector(action.pageUrl));

    let items: ISitePageVersion[] = versions?.items;

    if (!items) {
      items = yield call([sitePagesService, "getSitePageVersions"], sitePage.Id);
    }

    yield put(getSitePageVersionsSuccess(action.pageUrl, items));
  } catch (e) {
    yield put(getSitePagesHierarchyFailure(e));
    throw e;
  }
}

export function* setSitePageVersion(action: ISetSitePageVersionAction) {
  try {
    const sitePage = yield select(sitePageSelector(action.pageUrl));
    const clonedSitePage: ISitePage = _cloneDeep(sitePage);
    clonedSitePage.pageInfo = action.version.pageInfo;

    yield call([sitePagesService, "checkOutSitePage"], clonedSitePage.Id);
    yield call([sitePagesService, "updatePage"], clonedSitePage.Id, clonedSitePage);
    yield call([sitePagesService, "checkInSitePage"], clonedSitePage.Id);

    yield put(setSitePageVersionSuccess(action.pageUrl, clonedSitePage));
  } catch (e) {
    yield put(setSitePageVersionFailure(e));
    throw e;
  }
}

export function* setSitePageVersionHistoryMode(action: ISetSitePageVersionHistoryModeAction) {
  try {
    if (!action.toggle) {
      const sitePage = yield select(sitePageSelector(action.pageUrl));

      if (!sitePage) {
        return;
      }

      const files: IAttachedFile[] = yield setAttachedFiles(sitePage.pageInfo?.attachedFiles);
      yield put(updateSitePageFiles({ files, url: action.pageUrl }));
    }

    yield put(setSitePageVersionHistoryModeSuccess(action.toggle));
  } catch (e) {
    yield put(setSitePageVersionHistoryModeFailure(e));
    throw e;
  }
}

export function* selectPageVersion(action: ISelectSitePageVersionAction) {
  try {
    const pageVersions: ISitePageVersions = yield select(pageVersionsSelector);
    const { items } = pageVersions[action.pageUrl];
    const files: IAttachedFile[] = yield setAttachedFiles(items[action.versionIndex].pageInfo?.attachedFiles);

    yield put(updateSitePageFiles({ files, url: action.pageUrl }));
    yield put(selectSitePageVersionSuccess(action.pageUrl, action.versionIndex));
  } catch (e) {
    yield put(selectSitePageVersionFailure(e));
    throw e;
  }
}

export function* checkOutSitePage(action: ICheckOutSitePageRequestAction) {
  try {
    yield call([sitePagesService, "checkOutSitePage"], action.sitePageId);

    if (action.cb) {
      action.cb();
    }

    yield put(checkOutSitePageSuccess(action.sitePageUrl, action.currentUser));
  } catch (e) {
    yield put(checkOutSitePageFailure(e));
    throw e;
  }
}

export function* discardCheckOutSitePage(action: IDiscardCheckOutSitePageRequestAction) {
  try {
    yield call([sitePagesService, "discardCheckOutSitePage"], action.sitePageId);

    const allTerms = yield call(getAllTerms);
    const sitePage: ISitePage = yield call([sitePagesService, "getById"], action.sitePageId, allTerms);
    const files: IAttachedFile[] = yield setAttachedFiles(sitePage.pageInfo?.attachedFiles);

    yield put(updateSitePageFiles({ files, url: action.sitePageUrl }));
    yield put(discardCheckOutSitePageSuccess(action.sitePageUrl, sitePage));
  } catch (e) {
    yield put(discardCheckOutSitePageFailure(e));
    throw e;
  }
}

export function* saveAsDraftSitePage(action: ISaveAsDraftSitePageRequestAction) {
  try {
    const allTerms = yield call(getAllTerms);
    let sitePage: ISitePage = yield select(sitePageSelector(action.sitePageUrl));

    yield call([sitePagesService, "updatePage"], action.sitePageId, sitePage);
    sitePage = yield call([sitePagesService, "getById"], action.sitePageId, allTerms);

    yield put(saveAsDraftSitePageSuccess(action.sitePageUrl, sitePage));

    if (sitePage.friendlyUrl) {
      yield put(saveAsDraftSitePageSuccess(sitePage.friendlyUrl, sitePage));
    }
  } catch (e) {
    yield put(saveAsDraftSitePageFailure(e));
    throw e;
  }
}

export function* republishSitePage(action: IRepublishSitePageRequestAction) {
  try {
    const allTerms = yield call(getAllTerms);
    let sitePage: ISitePage = yield select(sitePageSelector(action.sitePageUrl));
    yield call([sitePagesService, "updatePage"], action.sitePageId, sitePage);
    yield call([sitePagesService, "checkInSitePage"], action.sitePageId);

    sitePage = yield call([sitePagesService, "getById"], action.sitePageId, allTerms);

    yield put(republishSitePageSuccess(action.sitePageUrl, sitePage));

    if (sitePage.friendlyUrl) {
      yield put(republishSitePageSuccess(sitePage.friendlyUrl, sitePage));
    }
  } catch (e) {
    yield put(republishSitePageFailure(e));
    throw e;
  }
}

export function* attachSitePageToDocuments(action: IAttachSitePageToDocumentsRequestAction) {
  try {
    const icons = yield call(getStandardsIcons);
    // TODO maybe after attachment of documents there is need to republish site page
    const sitePage: ISitePage = yield select(sitePageSelector(action.sitePageUrl));
    yield call(
      [sitePagesService, "attachPageToDocuments"],
      sitePage,
      action.documents,
      action.library,
      action.command,
      icons,
    );

    yield put(attachSitePageToDocumentsSuccess());
  } catch (e) {
    yield put(attachSitePageToDocumentsFailure(e));
    throw e;
  }
}

export function* addNewSitePage(action: IAddNewSitePageRequestAction) {
  try {
    const pageTitle: string = action.newPageInfo.pageTitle;
    const parentFolderPath: string = action.newPageInfo.folderServerRelativeUrl;
    const isExist = yield call([sitePagesService, "checkIfPageExist"], parentFolderPath, pageTitle);

    if (isExist) {
      yield put(addNewSitePageFailure());
      action.errorNotificationCb(`Looks like this page already exists`);
    } else {
      const allTerms = yield call(getAllTerms);
      const sitePage: ISitePage = yield call([sitePagesService, "addNewPage"], action.newPageInfo, allTerms);
      yield put(addNewSitePageSuccess());
      action.redirectCb(sitePage.fileInfo.ServerRelativeUrl);
    }
  } catch (e) {
    yield put(addNewSitePageFailure());
    throw e;
  }
}

export function* watchGetSitePageByFriendlyUrl() {
  yield takeEvery(SitePagesActionsTypes.GET_SITE_PAGE_BY_FRIENDLY_URL, getSitePageByFriendlyUrl);
}

export function* watchGetSitePageByUrl() {
  yield takeEvery(SitePagesActionsTypes.GET_SITE_PAGE_BY_URL, getSitePageByUrl);
}

export function* watchGetSitePagesHierarchy() {
  yield takeEvery(SitePagesActionsTypes.GET_SITE_PAGES_HIERARCHY_REQUEST, getSitePagesHierarchy);
}

export function* watchGetSitePageVersions() {
  yield takeEvery(SitePagesActionsTypes.GET_SITE_PAGE_VERSIONS, getSitePageVersions);
}

export function* watchSetSitePageVersion() {
  yield takeEvery(SitePagesActionsTypes.SET_SITE_PAGE_VERSION, setSitePageVersion);
}

export function* watchSetSitePageVersionHistoryMode() {
  yield takeEvery(SitePagesActionsTypes.SET_SITE_PAGE_VERSION_HISTORY_MODE, setSitePageVersionHistoryMode);
}

export function* watchSelectSitePageVersion() {
  yield takeEvery(SitePagesActionsTypes.SELECT_SITE_PAGE_VERSION, selectPageVersion);
}

export function* watchCheckOutSitePage() {
  yield takeEvery(SitePagesActionsTypes.CHECK_OUT_SITE_PAGE, checkOutSitePage);
}

export function* watchDiscardCheckOutSitePage() {
  yield takeEvery(SitePagesActionsTypes.DISCARD_CHECK_OUT_SITE_PAGE, discardCheckOutSitePage);
}

export function* watchSaveAsDraftSitePage() {
  yield takeEvery(SitePagesActionsTypes.SAVE_AS_DRAFT_SITE_PAGE, saveAsDraftSitePage);
}

export function* watchRepublishSitePage() {
  yield takeEvery(SitePagesActionsTypes.REPUBLISH_SITE_PAGE, republishSitePage);
}

export function* watchAttachSitePageToDocuments() {
  yield takeEvery(SitePagesActionsTypes.ATTACH_SITE_PAGE_TO_DOCUMENTS, attachSitePageToDocuments);
}

export function* watchAddNewSitePage() {
  yield takeEvery(SitePagesActionsTypes.ADD_NEW_SITE_PAGE_REQUEST, addNewSitePage);
}

export default function* sitePagesSagas(): Iterator<ForkEffect> {
  yield fork(watchGetSitePageByFriendlyUrl);
  yield fork(watchGetSitePageByUrl);
  yield fork(watchGetSitePagesHierarchy);
  yield fork(watchGetSitePageVersions);
  yield fork(watchSetSitePageVersion);
  yield fork(watchSetSitePageVersionHistoryMode);
  yield fork(watchCheckOutSitePage);
  yield fork(watchDiscardCheckOutSitePage);
  yield fork(watchSaveAsDraftSitePage);
  yield fork(watchRepublishSitePage);
  yield fork(watchAttachSitePageToDocuments);
  yield fork(watchAddNewSitePage);
  yield fork(watchSelectSitePageVersion);
}
